.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pulse-button {
  position: relative;
  border: none;
  box-shadow: 0 0 0 0 #b4b4b48e;
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

.pulse-button:hover {
  animation: none;
  -webkit-animation: none;
}

@keyframes pulse {
  0% {
    @include transform(scale(.9));
  }
  70% {
    @include transform(scale(1));
    box-shadow: 0 0 0 20px #17b97800;
  }
    100% {
    @include transform(scale(.9));
    box-shadow: 0 0 0 0 #17b97800;
  }
}

@-webkit-keyframes pulse {
  0% {
    @include transform(scale(.9));
  }
  70% {
    @include transform(scale(1));
    box-shadow: 0 0 0 50px #17b97800;
  }
    100% {
    @include transform(scale(.9));
    box-shadow: 0 0 0 0 #17b97800;
  }
}
img {
  max-width: 100%;
}